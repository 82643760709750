<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6"  class="white shadow1 rounded">
                <v-data-table
                    class="custom-data-table"
                    :headers="colorHeaders"
                    :items="$store.getters['color/colors']"
                    :search="searchColor"
                    :loading="$store.getters['color/loadingColors']" 
					loading-text="Loading... Please wait"
                >
                    <template v-slot:top>
                        <v-toolbar
                            dense
                            color="white"
                            :elevation="0"
                            height="50"
                        >
                            <v-toolbar-title class="subtitle-1"
                                >Color List</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Color"
                                    append-icon="mdi-magnify"
                                    style="width: 300px"
                                    v-model="searchColor"
                                >
                                </v-text-field>
                            </v-form>
                            <v-spacer></v-spacer>
                            <color-dialog :icon="true" ref="colorDialog"></color-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="editColor(item)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-circle-edit-outline</v-icon
                                >
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="colorId = item.id;$refs.confirmDialog.dialog = true"
                                    color="error"
                                    v-on="on"
                                    >mdi-delete-circle-outline</v-icon
                                >
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteColor"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "../../components/confirm-dialog.component";
import colorDialog from "../../components/color-dialog.component";

export default {
    name: 'Colors',

    components: {
		"confirm-dialog": confirmDialog,
		"color-dialog": colorDialog,
	},

    data() {
        return {
            colorHeaders: [
                { text: 'SL', value: 'sl' },
                { text: 'Color Name', value: 'name' },
                { text: 'Action', value: 'action' },
            ],
            searchUnit: "",
            colorId: null,
            loading: false,
        }
    },

    created() {
        this.$store.dispatch("color/getColors");
    },

    methods: {
        editColor(color){
            Object.keys(this.$refs.colorDialog.color).forEach(key => {
                this.$refs.colorDialog.color[key] = color[key];
            })
            this.$refs.colorDialog.showColorDialog = true;
        },
        deleteColor(){
            this.$store.dispatch('color/deleteColor', this.colorId);
            this.$refs.confirmDialog.dialog = false;
        }
    }
}
</script>

<style>

</style>